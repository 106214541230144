/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.6.0/dist/jquery.min.js
 * - /npm/core-js-bundle@3.1.2/minified.js
 * - /npm/react@16.12.0/umd/react.production.min.js
 * - /npm/react-dom@16.12.0/umd/react-dom.production.min.js
 * - /npm/prop-types@15.6.1/prop-types.min.js
 * - /npm/mobx@4.3.1/lib/mobx.umd.min.js
 * - /npm/mobx-react@5.2.3/index.min.js
 * - /npm/jquery-validation@1.17.0/dist/jquery.validate.min.js
 * - /npm/jquery-validation@1.17.0/dist/additional-methods.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
